export default (coordinates) => {
  const maxX = coordinates.map(
    ([x, y]) => x
  ).reduce(
    (acc, current) => Math.max(acc, current)
  )
  const minX = coordinates.map(
    ([x, y]) => x
  ).reduce(
    (acc, current) => Math.min(acc, current)
  )

  const maxY = coordinates.map(
    ([x, y]) => y
  ).reduce(
    (acc, current) => Math.max(acc, current)
  )

  const minY = coordinates.map(
    ([x, y]) => y
  ).reduce(
    (acc, current) => Math.min(acc, current)
  )

  return [
    minX + ((maxX - minX) / 2),
    minY + ((maxY - minY) / 2),
  ]
}


