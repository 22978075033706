import React, { createContext, useContext } from 'react'

const AppContext = createContext({
  lang: 'ar',
  busy: false,
  splitIsActive: false,
  canSplit: false,
  controllersActive: true,
  backgroundActive: true,
  canReset: false,
  maps: [],
  dataMaps: [],
  backgroundMaps: [],
  mapSettings: {}
})

export default AppContext
export const AppContextProvider = AppContext.Provider
export const withAppContext = (Element) => {
  const appData = useContext(AppContext)

  return <Element appData={appData} />
}
