import React, {Component, createContext} from 'react'
import _ from 'lodash'

export const MapContext = createContext({
  center: null,
  zoom: null,
  bearing: null,
  pitch: null,
  changeSettings: () => {},
})

export default class extends Component {
  constructor (props) {
    super(props)

    const {
      center,
      zoom,
      bearing = 0,
      pitch = 0,
    } = props.initialSettings

    this.changeSettings = (props) => {
      this.setState({
        ...this.state,
        ...props,
      })
    }

    this.props.reset(this.reset.bind(this))

    this.state = {
      center,
      zoom,
      bearing,
      pitch,
      changeSettings: this.changeSettings,
    }
  }

  reset () {
    const {changeSettings, ...stateValues} = this.state

    this.props.mapSettingsChange({
      ...stateValues,
      bearing: 0,
      pitch: 0,
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const stateChanged = !Object.is(prevState, this.state)
    const propsChanges = !Object.is(prevProps, this.props)
    const {changeSettings, ...stateValues} = this.state
    const passedSettingsChanged = !_.isEqual(this.props.initialSettings, stateValues)

    if (stateChanged) {
      const {bearing, pitch} = this.state

      if (bearing !== 0 || pitch !== 0) {
        this.props.resetAvailable()
      }

      if (passedSettingsChanged) {
        const {changeSettings, ...state} = this.state
        this.props.mapSettingsChange(state)
      }
    }

    if (propsChanges) {
      if (passedSettingsChanged) {
        this.setState({...this.props.initialSettings})
      }
    }
  }

  render () {
    const {children} = this.props

    return (
      <MapContext.Provider value={this.state}>
        <>
          {children}
        </>
      </MapContext.Provider>
    )
  }
}
