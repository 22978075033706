import React, {Component, createRef} from 'react'
import {Map} from '.'
import jQuery from 'jquery'

export default class extends Component {
  constructor (props) {
    super(props)

    this.state = {
      dragIsActive: false
    }

    this.window = null
    this.secondElement = createRef()
    this.handleMouseMove = this.handleMouseMove.bind(this)
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.window = jQuery(window)
    }
  }

  handleMouseMove (e) {
    if (typeof window === 'undefined') {
      return null
    }

    const {current: secondElement} = this.secondElement
    const ratio = (window.innerWidth - e.clientX)
      / window.innerWidth

    jQuery(secondElement).css({width: `${100 - (ratio * 100)}%`})
  }

  componentDidUpdate (prevProps, prevState) {
    const stateChanged = !Object.is(prevState, this.state)

    if (stateChanged) {
      const {dragIsActive} = this.state

      if (dragIsActive) {
        this.window.on('mousemove', this.handleMouseMove)
      } else {
        this.window.off('mousemove', this.handleMouseMove)
      }
    }
  }

  componentWillUnMount () {
    this.window.off('mousemove', this.handleMouseMove)
  }

  render () {
    const {maps, children} = this.props
    const activeMaps = maps.filter(({active}) => active)
    const [firstMap, secondMap] = activeMaps

    if (activeMaps.length !== 2) {
      return (null)
    }

    return (
      <div className="map-view comparsion">
        {children}
        <div className="first-map">
          <Map
            map={firstMap}
          />
        </div>
        <div ref={this.secondElement} className="second-map">
          <div
            onMouseDown={() => this.setState({dragIsActive: true})}
            onMouseUp={() => this.setState({dragIsActive: false})}
            className="controller">
            <div
              className="controller-pin d-flex justify-content-center align-items-center">
              <i className="fas fa-arrows-alt-h"></i>
            </div>
          </div>

          <div className="w-100 h-100 overflow-hidden position-relative">
            <div className="second-map-container">
              <Map
                map={secondMap}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
