import React, {Component, createRef, useContext} from 'react'
import _ from 'lodash'
// import 'mapbox-gl/dist/mapbox-gl.css'

import {MapContext} from './sync-maps'
import mapboxgl from '../../services/mapbox-gl'
import getPolygonCenter from '../../utils/get-polygon-center'
import AppContext from '../../contexts/AppContext'

class Map extends Component {
  static contextType = MapContext

  constructor (props) {
    super(props)

    this.state = {
      zoom: null,
      center: null,
      bearing: null,
      pitch: null,
    }

    this.ref = createRef()
    this.handleMoving = this.handleMoving.bind(this)
    this.markers = []
    this.setMarkersOnMap = this.setMarkersOnMap.bind(this)
  }

  setMarkersOnMap () {
    const features = this.getMapFeatures()

    for (let marker of this.markers) {
      marker.remove()
    }

    this.markers.length = 0

    for (let feature of features) {

      const {properties, coordinates: [x]} = feature
      const {direction = null} = properties
      const label = document.createElement('div')
      const labelBody = document.createElement('div')
      const labelPoint = document.createElement('div')
      const labelDocoration = document.createElement('div')

      const directionClass = !isNaN(direction) && +direction > 0 && +direction <= 12
        ? `clock-${ `0${direction}`.slice(-2) }`
        : ['left', 'right'].includes(direction)
          ? direction
          : x > 150 ? 'left': 'right'

      labelBody.innerHTML = properties.title
      label.className = `custom-marker ${directionClass}`
      labelBody.className = 'custom-marker-body px-1 py-1'
      labelDocoration.className = 'custom-marker-decoration'
      labelPoint.className = 'custom-marker-point'

      label.appendChild(labelBody)
      label.appendChild(labelDocoration)
      label.appendChild(labelPoint)
      const marker = new mapboxgl.Marker(label)
        .setLngLat(feature.coordinates)
        .addTo(this.map)

      this.markers.push(marker)
    }
  }

  getMapFeatures () {
    const {lang} = this.props
    const allowedGeometryTypes = [
      'Point',
      'Polygon',
      'LineString',
      // 'MultiPolygon',
      'MultiLineString',
    ]

    const features = this.map.queryRenderedFeatures()
      .filter(
        ({geometry: {type}}) => allowedGeometryTypes
        .includes(type)
      )
      .map(
        feature => {
          let centerPoint
          const {geometry: {type, coordinates}, properties} = feature

          switch (type.toLowerCase()) {
            case 'point': {
              centerPoint = coordinates
              break
            }

            case 'polygon': {
              centerPoint = getPolygonCenter(coordinates[0])
              break
            }

            case 'linestring': {
              centerPoint = coordinates[Math.ceil((coordinates.length - 1) / 2)]
              break
            }

            case 'multipolygon': {
              const newCoordinates = coordinates.map(
                polygonCoordinates => getPolygonCenter(polygonCoordinates[0])
              )

              centerPoint = getPolygonCenter(newCoordinates)
              break
            }

            case 'multilinestring': {
              centerPoint = coordinates[0][Math.ceil((coordinates[0].length - 1)  / 2)]
              break
            }

            default: {
              throw new Error(`unrecognized geometry ${type} was given`)
            }
          }

          return {
            properties,
            coordinates: centerPoint
          }
        })
      .filter(({properties}) => !!properties.title)

    const editedList = []

    features
      .map(({properties: {title}}) => title)
      .forEach(
        (title) => {
          const list = features
            .filter(
              ({properties: {title: innerTitle}}) => innerTitle === title
            )

          const item = list[
            Math.ceil(
              list.length / 2
            ) - 1
          ]

          editedList.push(item)
        }
      )

    return editedList
      .reduce(
        (acc, item) => {
          const {coordinates} = item

          const identicalLocation = acc
            .map(
              ({coordinates}) => coordinates
            )
            .find(
              coo => _.isEqual(coo, coordinates)
            )

          if ( !identicalLocation ) {
            acc.push(item)
          }

          return acc
        },
        []
      )
      .map(
        row => {
          let { title } = row.properties
          const defaultTitle = row.properties.title
          const langTitle = row.properties[`title_${lang}`]

          if (langTitle) {
            title = langTitle
          }

          return {
            ...row,
            properties: {
              ...row.properties,
              title,
            }
          }
        }
      )
  }

  componentDidMount () {
    const {current: container} = this.ref
    const {map} = this.props
    const {id, mapId} = map
    const {center, zoom, bearing = 0, pitch = 0} = this.context
    this.id = id

    this.map = new mapboxgl.Map({
      container,
      zoom,
      center,
      bearing,
      pitch,
      style: mapId
    })

    this.map.on('sourcedata', this.setMarkersOnMap)
    this.map.on('moveend', this.setMarkersOnMap)
    this.map.on('load', this.setMarkersOnMap)

    this.map.on('load', this.handleMoving)
    this.map.on('move', this.handleMoving)
  }

  jump () {
    const {center, zoom, bearing, pitch} = this.context

    this.map.jumpTo({
      center,
      zoom,
      bearing,
      pitch
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const stateChanged = !_.isEqual(prevState, this.state)
    const propsChanged = !_.isEqual(prevProps, this.props)
    const {changeSettings} = this.context

    const {
      center,
      zoom,
      bearing,
      pitch
    } = this.state

    const contextIsDifferentThanState = !_.isEqual(
      {
        center: this.state.center,
        zoom: this.state.zoom,
        bearing: this.state.bearing,
        pitch: this.state.pitch,
      }, {
        center: this.context.center,
        zoom: this.context.zoom,
        bearing: this.context.bearing,
        pitch: this.context.pitch,
      }
    )

    if (stateChanged) {
      if (contextIsDifferentThanState) {
        changeSettings({
          center,
          zoom,
          bearing,
          pitch
        })
      }
    } else {
      if (!propsChanged) {
        if (contextIsDifferentThanState) {
          this.jump()
        }
      }
    }

    if (propsChanged) {
      if (this.props.map.mapId !== prevProps.map.mapId) {
        this.map.setStyle(this.props.map.mapId)
      }
    }
  }

  handleMoving () {
    const {changeSettings} = this.context
    const zoom = this.map.getZoom()
    const bearing = this.map.getBearing()
    const pitch = this.map.getPitch()
    const center = Object.values(
      this.map.getCenter()
    )

    this.setState({
      center,
      zoom,
      bearing,
      pitch
    })
  }

  componentWillUnmount () {
    this.map.remove()
  }

  render () {
    const {ready} = this.state
    const {id='', className = '', style = {}} = this.props

    return (
      <div id={id} className={className} style={style} ref={this.ref}></div>
    )
  }
}

export default (props) => {
  const {map, className, style: styleObject = {}} = props
  const {id, stylesIDs} = map
  const {lang} = useContext(AppContext)

  const styles = stylesIDs
    .split(/\n/)
    .filter(style => !!style)

  return (
    <>
      {
        styles.map(
          (style, i) => (
            <Map
              className={className}
              key={i.toString()}
              id={`${map.id}-${i}`}
              style={styleObject}
              lang={lang}
              map={
                {
                  ...map,
                  mapId: style,
                }
              }
            />
          )
        )
      }
    </>
  )
}
