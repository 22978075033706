import React from 'react'
import {SortableContainer} from 'react-sortable-hoc';
import SortableItem from './sortable-item'

export default SortableContainer(
  ({maps, toggleMap, handleMapOpacityChange, className=''}) => {
    return (
      <div className={className}>
        {maps.map((map, i) => (
          <SortableItem
            key={i.toString()}
            index={i}
            map={map}
            toggleMap={toggleMap}
            handleMapOpacityChange={handleMapOpacityChange}
          />
        ))}
      </div>
    );
  },
);


