import i18n from 'i18next'
import { I18nextProvider, withTranslation as I18nWithTranslation } from 'react-i18next'
import { initReactI18next } from 'react-i18next'
import ar from './ar'
import en from './en'

export default i18n
  .use(initReactI18next)
  .init({
    resources: {
      lng: 'en',
      en: {
        translation: en,
      },
      ar: {
        translation: ar,
      },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  })
