import React, { Component, createRef } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { Helmet } from 'react-helmet'
import jQuery from 'jquery'
import queryString from 'query-string'
import PersianJS from 'persianjs'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles'
import { AppContextProvider } from '../contexts/AppContext'
import { encode as base64Encode, decode as base64Decode } from '../utils'

import Watermark from '../images/logo.svg'

import {
  MapItem,
  SyncMaps,
  Map,
  CompareMaps,
  SortableList
} from '../components/maps'

const HOME_URL = 'https://almadaq.net'
const API_URL = 'https://wordpress.almadaq.net/wp-json'

class MapWrapper extends Component {
  constructor(props) {
    super(props)

    this.initialMapSetttings = {
      center: [30.0459487, 31.2454152].reverse(),
      zoom: 12
    }

    this.sharedProperties = [
      'splitIsActive',
      'canSplit',
      'controllersActive',
      'backgroundActive',
      'canReset',
      'backgroundMaps',
      'dataMaps',
      'mapSettings'
    ]

    this.state = {
      lang: null,
      busy: false,
      splitIsActive: false,
      canSplit: false,
      controllersActive: true,
      backgroundActive: true,
      canReset: false,
      maps: [],
      dataMaps: [],
      backgroundMaps: [],
      mapSettings: { ...this.initialMapSetttings }
    }

    this.urlInput = createRef()
    this.toggleControllers = this.toggleControllers.bind(this)
    this.toggleMap = this.toggleMap.bind(this)
    this.toggleSplit = this.toggleSplit.bind(this)
    this.handleMapOpacityChange = this.handleMapOpacityChange.bind(this)
    this.sortDataMaps = this.sortDataMaps.bind(this)
    this.sortBackgroundMaps = this.sortBackgroundMaps.bind(this)
    this.toggleBackground = this.toggleBackground.bind(this)
    this.resetView = this.resetView.bind(this)
    this.resetAvailableCheck = this.resetAvailableCheck.bind(this)
    this.handleMapSettingsChange = this.handleMapSettingsChange.bind(this)
  }

  handleMapSettingsChange(data) {
    this.setState({
      mapSettings: data
    })
  }

  resetAvailableCheck() {
    const { canReset } = this.state

    if (!canReset) {
      this.setState({
        canReset: true
      })
    }
  }

  resetView(e) {
    e.preventDefault()

    if (typeof this.resetFn === 'function') {
      this.resetFn()
    }

    this.setState({
      canReset: false
    })
  }

  toggleBackground() {
    const { backgroundActive } = this.state

    this.setState({
      backgroundActive: !backgroundActive
    })
  }

  sortDataMaps ({oldIndex, newIndex}) {
    const {dataMaps} = this.state
    const mapsClone = [...dataMaps]
    const item = mapsClone.splice(oldIndex, 1)[0]

    mapsClone.splice(newIndex, 0, item)
    this.setState({
      dataMaps: [...mapsClone]
    })
  }

  sortBackgroundMaps({ oldIndex, newIndex }) {
    const { backgroundMaps } = this.state
    const mapsClone = [...backgroundMaps]
    const item = mapsClone.splice(oldIndex, 1)[0]

    mapsClone.splice(newIndex, 0, item)
    this.setState({
      backgroundMaps: [...mapsClone]
    })
  }

  toggleSplit() {
    const { splitIsActive } = this.state

    this.setState({
      splitIsActive: !splitIsActive
    })
  }

  handleMapOpacityChange(map, value) {
    const { maps } = this.state

    const index = maps.indexOf(map)
    const mapsClone = [...maps]

    mapsClone[index] = {
      ...map,
      opacity: value / 100
    }

    this.setState({
      maps: [...mapsClone]
    })
  }

  toggleControllers(status) {
    const { controllersActive } = this.state

    this.setState({
      controllersActive: status !== undefined ? status : !controllersActive
    })
  }

  toggleMap(givenMap) {
    const { maps } = this.state
    const mapsClone = [...maps]
    let index = null

    for (let i = 0; i < maps.length; i++) {
      const map = mapsClone[i]

      if (Object.is(map, givenMap)) {
        index = i
      }
    }

    mapsClone[index] = {
      ...mapsClone[index],
      active: !mapsClone[index].active
    }

    this.setState({
      splitIsActive: false,
      maps: [...mapsClone]
    })
  }

  async componentDidMount() {
    const { search } = location
    const { lang='ar' } = queryString.parse(search)
    const { i18n } = this.props

    i18n.changeLanguage(lang)
    this.setState({
      lang,
      busy: true,
    })

    const mapNodes = await fetch(
      `${API_URL}/wp/v2/map?filter[orderby]=acf___map_order&order=desc&per_page=100`
    )
      .then(
        async response => {
          const body = await response.json()

          if (!response.ok) {
            throw new Error(JSON.strinigfy(body))
          }

          return body
        })
      .catch((e) => {
        throw e
      })
      .finally(() => {
        this.setState({
          busy: false
        })
      })

    const maps = mapNodes
      .map(
        map => {
          const {
            id,
            title: { rendered: name },
            acf: { styles_ids: stylesIDs, type }
          } = map

          return {
            id,
            name,
            stylesIDs,
            type: type || 'background',
            opacity: 1
          }
        }
      )

    try {
      const {_cntxt: context = null, _hc: hashedContext = null} = queryString.parse(search)
      let queryStringContextData = null

      try {
        if (hashedContext) {
          const url = new URL(`${API_URL}/url_shortener/decode`)
          const searchParams = new URLSearchParams({hash: hashedContext})

          url.search = searchParams.toString()

          const {state} = await fetch(url)
            .then(
              async response => {
                const body = response.json()

                if (response.ok) {
                  return body
                }

                throw new Error(
                  JSON.stringify(
                    body
                  )
                )
              }
            )

          if (!state) {
            throw new Error('no state was found')
          }

          queryStringContextData = base64Decode(state)
        }
      } catch (e) {
        console.warn(e)
      }

      if (!queryStringContextData) {
        if (context) {
          queryStringContextData = base64Decode(context)
        } else {
          throw new Error('error while parsing the query string')
        }
      }

      const editedMaps = queryStringContextData.maps
        .filter(
          (map) => map.active || !map.hasOwnProperty('active')
        )
        .map(
          sharedMap => {
            const map = maps.find(
              map => {
                return map.id === sharedMap.id
              }
            )

            if ( sharedMap ) {
              return {
                ...map,
                ...sharedMap,
              }
            } else {
              return null
            }
          }
        )
        .filter(
          map => !!map
        )
        .reduce(
          (acc, item, index, maps) => {
            if (item.type === 'background') {
              acc.push(item)
              return acc
            }

            const dataMapsCounteredCount = acc
              .filter(
                ({type}) => type === 'data'
              )
              .length

            const isFirstDataMap = !dataMapsCounteredCount

            if (isFirstDataMap) {
              item.active = true
            } else {
              item.active = false
            }

            item.name = lang === 'en' ? dataMapsCounteredCount + 1
              : PersianJS(`${dataMapsCounteredCount + 1}`).englishNumber().toString()

            acc.push(item)
            return acc
          }, []
        )

      this.setState({
        ...queryStringContextData,
        maps: [
          ...editedMaps,
        ],
      })
    } catch (e) {
      console.warn(e)

      this.setState({
        maps
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const stateChanged = !Object.is(prevState, this.state)

    if (stateChanged) {
      if (!Object.is(this.state.maps, prevState.maps)) {
        const {maps} = this.state

        const activeMaps = maps
          .filter(({type}) => type === 'background')
          .filter(
            ({active}) => active
          )

        const reducer = (set) => {
          return (acc, item, i, data) => {
            const setItem = set.find(map => {
              return map.id === item.id
            })

            if (setItem) {
              const setItemIndex = set.indexOf(setItem)
              acc[setItemIndex] = item
            }

            return acc
          }
        }

        const backgroundMaps = !this.state.backgroundMaps.length
          ? maps.filter(({type}) => type === 'background')
          : maps.filter(({type}) => type === 'background')
          .reduce(reducer(this.state.backgroundMaps), [])

        const dataMaps = !this.state.dataMaps.length
          ? maps.filter(({type}) => type === 'data')
          : maps
          .filter(({type}) => type === 'data')
          .reduce(reducer(this.state.dataMaps), [])

        this.setState({
          dataMaps,
          backgroundMaps,
          canShowInfo: activeMaps.length === 1,
          canSplit: activeMaps.length === 2,
        })
      }
    }
  }

  render() {
    const { t } = this.props
    const {
      busy,
      canSplit,
      splitIsActive,
      maps,
      dataMaps,
      backgroundMaps,
      controllersActive,
      backgroundActive,
      canReset,
      mapSettings,
      lang,
    } = this.state

    if (!lang) {
      return (null)
    }

    const activeMap = maps.find(({ active }) => active)
    const dir = lang === 'ar' ? 'rtl' : 'ltr'

    return (
      <AppContextProvider value={this.state}>
        <ThemeProvider theme={createMuiTheme({
          direction: dir,
        })}>

        <Helmet>
          <html lang={lang} dir={dir} />
        </Helmet>

        <div
          className={`map-component vh-100 ${
          controllersActive ? 'controllers-is-active' : ''
          }`}
          >
            <SyncMaps
              mapSettingsChange={this.handleMapSettingsChange}
              reset={(fn) => (this.resetFn = fn)}
              initialSettings={mapSettings}
              resetAvailable={this.resetAvailableCheck}
            >
              {splitIsActive && (
                <CompareMaps maps={backgroundMaps}>
                  <Map
                    id="default-map"
                    className={!backgroundActive ? 'd-none' : ''}
                    style={{
                      zIndex: 0
                    }}
                    map={{
                      id: 'default-map',
                      stylesIDs: 'mapbox://styles/mapbox/satellite-streets-v10'
                    }}
                  />
                </CompareMaps>
              )}

              <div className="map-view">
                {backgroundActive && (
                  <Map
                    id="default-map"
                    className={!backgroundActive ? 'd-none' : ''}
                    style={{
                      zIndex: 0
                    }}
                    map={{
                      id: 'default-map',
                      stylesIDs: 'mapbox://styles/mapbox/satellite-streets-v10'
                    }}
                  />
                )}

                {dataMaps.map((map, i) => {
                  return (
                    map.active && (
                      <Map
                        id={`_${map.id}`}
                        style={{
                          opacity: map.opacity,
                          zIndex: maps.length - i
                        }}
                        className={`${map.active ? 'visible' : 'invisible'}`}
                        key={i.toString()}
                        map={map}
                      />
                    )
                  )
                })}

                {backgroundMaps.map((map, i) => {
                  return (
                    map.active && (
                      <Map
                        id={`_${map.id}`}
                        style={{
                          opacity: map.opacity,
                          zIndex: backgroundMaps.length - i
                        }}
                        className={`${map.active ? 'visible' : 'invisible'}`}
                        key={i.toString()}
                        map={map}
                      />
                    )
                  )
                })}
              </div>
            </SyncMaps>

            <section className="controllers" style={{ textAlign: 'ltr' }}>
              <div className="toggle-back">
                <div className="actions">
                  <div className="action">
                    <button
                      onClick={() => this.toggleControllers(true)}
                      className="btn btn-link"
                    >
                      <div className="angle" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="controllers-container d-flex flex-column py-3">
                <div className="filters px-4">
                  {busy ? (
                    <div className="mb-2">
                      <span>{t('control tools')}</span>
                      <i className="fa fa-spinner fa-pulse mx-2" />
                    </div>
                  ) : (
                    <>
                      <SortableList
                        className={`${dataMaps.length ? 'mb-4' : ''}`}
                        shouldCancelStart={(e) => {
                          const $el = jQuery(e.target).closest(
                            '.filter-heading, .slider-component'
                          )

                          if ($el.length) {
                            return true
                          }
                        }}
                        lockAxis="y"
                        maps={backgroundMaps}
                        toggleMap={this.toggleMap}
                        handleMapOpacityChange={this.handleMapOpacityChange}
                        onSortEnd={this.sortBackgroundMaps}
                      />

                      <SortableList
                        shouldCancelStart={(e) => {
                          const $el = jQuery(e.target).closest(
                            '.filter-heading, .slider-component'
                          )

                          if ($el.length) {
                            return true
                          }
                        }}
                        lockAxis="y"
                        maps={dataMaps}
                        toggleMap={this.toggleMap}
                        handleMapOpacityChange={this.handleMapOpacityChange}
                        onSortEnd={this.sortDataMaps}
                      />
                    </>
                  )}
                </div>
                <div className="actions mt-auto px-4">
                  <div className="heading">{t('control tools')}</div>
                  <div className="d-flex justify-content-center pt-3">
                    <Tooltip
                      title={
                        !canSplit
                          ? t('can split message')
                          : ''
                      }
                    >
                      <div className={`action ml-2 ${!canSplit ? 'disabled' : ''}`}>
                        <button
                          onClick={this.toggleSplit}
                          className="btn btn-link"
                          disabled={!canSplit}
                        >
                          <div className="split">
                            <div className="column" />
                            <div className="dot" />
                          </div>
                        </button>
                      </div>
                    </Tooltip>

                    <div className="action">
                      <button
                        onClick={() => this.toggleControllers(false)}
                        className="btn btn-link"
                      >
                        <div className="angle" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="additional-tools">
              <button
                disabled={!canReset}
                className="btn btn-link"
                onClick={this.resetView}
              >
                <i className="fas fa-redo-alt" />
              </button>
            </section>

            <section className="watermark d-none">
              <a href="http://almadaq.com" target="_blank">
                <img src={Watermark} alt="Almadaq" />
              </a>
            </section>
          </div>
        </ThemeProvider>
      </AppContextProvider>
    )
  }
}

export default withTranslation()(MapWrapper)
