import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import Switch from '@material-ui/core/Switch'

const CustomSwitch = withStyles(theme => ({
  root: {
    'margin-top': '-5px',
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#808080',
        opacity: 1,
      },
    },
  },
  track: {
    'background-color': 'transparent',
    'border': '2px solid #808080',
    opacity: 1,
  },
  checked: {},
}))(Switch)

export default class extends Component {
  render () {
    const {
      toggleMap,
      map,
      handleMapOpacityChange,
    } = this.props

    const {type} = map
    const dragIconSpacing = type === 'background' ? 'ml-3' : 'ml-1'
    const labelMarginTop = type === 'data' ? 'mt-1' : ''

    return (
      <div className={`map-item filter ${map.active ? 'active' : 'mb-1 '}`}>
        <div className="d-flex">
          <i className={`fas fa-grip-vertical icon ${dragIconSpacing}`}></i>
          <div className="d-flex flex-column w-100">
            {
              type === 'background' && (
                <>
                  <div onClick={toggleMap.bind(this, map)} className="filter-heading d-flex align-items-top">
                    <div className="checkbox ml-2 mt-1"></div>
                    <div style={{maxWidth: '140px'}} dangerouslySetInnerHTML={{__html: map.name}}></div>
                  </div>
                  {
                    (map.active && (
                      <Slider
                        onChange={(_, value) => handleMapOpacityChange(map, value)}
                        value={Math.round(map.opacity * 100)}
                        className="slider-component"
                        aria-labelledby="input-slider"
                      />
                    )) || null
                  }
                </>
              )
            }

            {
              type === 'data' && (
                <div className="filter-heading d-flex align-items-top">
                  <CustomSwitch
                    checked={map.active}
                    onChange={toggleMap.bind(this, map)}
                  />
                  <div style={{maxWidth: '140px', marginTop: '2px'}} dangerouslySetInnerHTML={{__html: map.name}}></div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}
