import React from 'react'
import {SortableElement} from 'react-sortable-hoc';
import MapItem from './map-item'

export default SortableElement(
  ({map, toggleMap, handleMapOpacityChange}) => {
    return (
      <MapItem
        map={map}
        toggleMap={() => toggleMap(map)}
        handleMapOpacityChange={(_, value) =>
          handleMapOpacityChange(map, value)
        }
      />
    );
  },
);


