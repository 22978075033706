export const encode = (data) => {
  const dataString = JSON.stringify(data)

  return Buffer.from( dataString, 'utf-8')
    .toString('base64')
}

export const decode = (string) => {
  const dataString = Buffer.from(
    string, 'base64'
  ).toString('utf-8')

  return JSON.parse(dataString)
}

